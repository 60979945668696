import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CreateOutletDto, FeatureDto, FindOutletDto, OutletDetailDto, OutletDto, PageResultDto, UpdateOutletDto } from '../dto'

@Injectable()
export class OutletService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findOutletDto: FindOutletDto) {
    return await this.httpService.get<PageResultDto<OutletDto>>('/admin/v1/outlets', findOutletDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<OutletDetailDto>(`/admin/v1/outlets/${id}`)
  }

  async create(createOutletDto: CreateOutletDto) {
    return await this.httpService.post<OutletDto>(`/admin/v1/outlets`, createOutletDto)
  }

  async update(id: string, updateOutletDto: UpdateOutletDto) {
    return await this.httpService.put<FeatureDto>(`/admin/v1/outlets/${id}`, updateOutletDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/v1/outlets/${id}`)
  }

  async setEnable(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/admin/v1/outlets/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/admin/v1/outlets/${id}/disable`)
  }
}
