import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'

import { AuthStore, AuthQuery } from '../store/auth'

import { HttpService, AuthService, LocalStorageService, FileService, PageService, UserService, ProfileService, SettingService, LocationService, WindowRef, PromotionService, PushNotificationService, DashboardService, RewardService, OutletService, BirthdayService, BlueTechService } from '@api/services'
import { ApiModuleOptions } from './api.module-options'
import { DragulaService } from 'ng2-dragula'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    UserService,
    ProfileService,
    SettingService,
    LocationService,
    PageService,
    AuthStore,
    AuthQuery,
    PromotionService,
    BlueTechService,
    PageService,
    PushNotificationService,
    DashboardService,
    WindowRef,
    DragulaService,
    RewardService,
    OutletService,
    BirthdayService
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
