export { AuthService } from './auth.service'
export { HttpService } from './http.service'
export { LocalStorageService } from './local-storage.service'
export { FileService } from './file.service'
export { UserService } from './user.service'
export { ProfileService } from './profile.service'
export { SettingService } from './settting.service'
export { LocationService } from './location.service'
export { WindowRef } from './window-ref.service'
export { PromotionService } from './promotion.service'
export { PushNotificationService } from './push-notification.service'
export { DashboardService } from './dashboard.service'
export { PageService } from './page.service'
export { RewardService } from './reward.service'
export { OutletService } from './outlet.service'
export { BirthdayService } from './birthday.service'
export { BlueTechService } from './blue-tech.service'