import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { BlueTechTokenDto } from '@api/dto'

import { HttpService } from './http.service'

@Injectable()
export class BlueTechService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async getToken() {
    return await this.httpService.get<BlueTechTokenDto>(`/v1/blue-tech/token`)
  }
}
