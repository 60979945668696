import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { BirthdayDto, FeatureDto, UpdateBirthdayDto } from '../dto'

@Injectable()
export class BirthdayService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<BirthdayDto[]>('/admin/v1/birthday')
  }

  async findOne(id: string) {
    return await this.httpService.get<BirthdayDto>(`/admin/v1/birthday/${id}`)
  }

  async update(id: string, updateBirthdayDto: UpdateBirthdayDto) {
    return await this.httpService.put<FeatureDto>(`/admin/v1/birthday/${id}`, updateBirthdayDto)
  }
}
